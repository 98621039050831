import NavBar from "@/components/NavBar";
import constants from "@/constants";
import globalModel from "@/models/global.model";
import verifyModel from "@/models/verify.model";
import { REGEX_CAPTCHA } from "@/utils/regex";
import { useReactive } from "ahooks";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Field,
  Form,
  NumberKeyboard,
  PasswordInput,
  Toast,
} from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";

export default () => {
  const { user } = useSnapshot(globalModel.state);
  const state = useReactive<any>({
    visible: false,
    mobile: "",
    captcha: "",
    countdown: 0,
    countdownIndex: null,
    captchaVisible: false,
    isAgreed: false,
  });
  const nav = useNavigate();
  const [form] = Form.useForm();
  const pass = Form.useWatch("pass", form);
  const onFinish = async (values: any) => {
    if (values.pass !== values.passConfirm) {
      Toast("两次密码不一致");
      return;
    }
    const update = Toast({
      type: "loading",
      message: "正在设置...",
      forbidClick: true,
      duration: 0,
    });
    const res = await globalModel.pass({
      ...values,
    });
    if (res?.code === 200) {
      update.config({
        type: "success",
        message: "设置成功",
        forbidClick: true,
      });
      nav(-1);
      form.resetFields();
    }
    setTimeout(() => {
      update.clear();
    }, 2000);
  };
  const handleSendSMS = async () => {
    try {
      // @ts-ignore
      const captcha = new TencentCaptcha(
        constants.tencent.captchaId,
        async (data: any) => {
          if (data?.ret !== 0) {
            Toast(data?.errorMessage ?? "验证失败");
            return;
          }
          const update = Toast({
            type: "loading",
            message: "正在发送...",
            forbidClick: true,
            duration: 0,
          });
          const res = await verifyModel.sendVerifyCode({
            mobile: user.mobile,
            randstr: data.randstr,
            ticket: data.ticket,
          });
          if (res?.code === 200) {
            update.config({
              type: "success",
              message: "发送成功",
              forbidClick: true,
            });
            localStorage.setItem(
              "code-time",
              dayjs().format("YYYY-MM-DD HH:mm:ss")
            );
            state.countdown = 60;
          } else {
            update.config({
              type: "fail",
              message: "发送失败",
              forbidClick: true,
            });
          }
          captcha.destroy();
          setTimeout(() => {
            update.clear();
          }, 2000);
        }
      );
      captcha.show();
    } catch (e: any) {
      console.log(e);
      Toast(e.errorFields[0].errors[0]);
    }
  };

  useEffect(() => {
    const sendSMSCodeTime = localStorage.getItem("code-time");
    if (sendSMSCodeTime) {
      const diff = dayjs().diff(dayjs(sendSMSCodeTime), "s");
      if (diff < 60) {
        state.countdown = 60 - diff;
      }
    }
  }, []);

  useEffect(() => {
    if (state.countdown > 0 && !state.countdownIndex) {
      state.countdownIndex = setInterval(() => {
        state.countdown = state.countdown - 1;
      }, 1000);
    }
    if (state.countdown <= 0) {
      if (state.countdownIndex) {
        state.countdownIndex = clearInterval(state.countdownIndex);
      }
      localStorage.setItem("code-time", "");
    }
  }, [state.countdown, state.countdownIndex]);
  return (
    <div className={styles.page}>
      <NavBar title="设置安全密码" />
      <Form
        className={styles.form}
        layout="vertical"
        border={false}
        form={form}
        onFinish={onFinish}
        footer={
          <div className={styles.footer}>
            <Button
              className={styles.submit}
              type="primary"
              size="large"
              round
              block
              onClick={async () => {
                try {
                  await form.validateFields();
                  form.submit();
                } catch (e: any) {
                  Toast(e.errorFields[0].errors[0]);
                }
              }}
            >
              立即设置
            </Button>
          </div>
        }
      >
        <Form.Item
          name="pass"
          label="安全密码"
          rules={[{ required: true, message: "请输入安全密码" }]}
        >
          <PasswordInput gutter={10} autoFocus />
        </Form.Item>
        <Form.Item
          name="passConfirm"
          label="确认密码"
          rules={[{ required: true, message: "请确认密码" }]}
        >
          <PasswordInput gutter={10} />
        </Form.Item>
        <Form.Item
          name="captcha"
          label="验证码"
          rules={[
            { required: true, message: "请输入验证码" },
            {
              pattern: REGEX_CAPTCHA,
              message: "请输入正确的验证码",
            },
          ]}
        >
          <Field
            className={styles.captcha}
            placeholder="请输入验证码"
            type="number"
            maxLength={6}
            suffix={
              <a
                className={styles.countdown}
                onClick={async () => {
                  if (state.countdown <= 0) {
                    await handleSendSMS();
                  }
                }}
              >
                {state.countdown <= 0
                  ? "获取验证码"
                  : `${state.countdown}s后可重新发送`}
              </a>
            }
            clearable
          />
        </Form.Item>
      </Form>
      <NumberKeyboard
        closeButtonText="完成"
        visible={state.visible}
        onInput={(v) => {
          form.setFieldValue("pass", `${pass ?? ""}${v}`);
        }}
        onDelete={() => {
          const str = pass.toString();
          form.setFieldValue("pass", `${str.slice(0, str.length - 1) ?? ""}`);
        }}
        onHide={() => (state.visible = false)}
        onClose={() => (state.visible = false)}
        onBlur={() => (state.visible = false)}
      />
    </div>
  );
};
